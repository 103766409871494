body {
  margin: 0;
}

.w-100 {
  width: 100%;
}

.hypt-draft-wrapper {
  border: 1px solid #ccc;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.hypt-draft-toolbar {}

.hypt-draft-editor {
  flex: 1;
  padding: 10px 25px;
}

#chakra-toast-manager-bottom {
  flex-direction: row !important;
  align-items: flex-end !important;
}